<template>
  <div class="measure-list report-container container-fluid">
    <measure-list-item
      v-if="loaded && measure.sessions_completed"
      :read-only="true"
      :missing-answers="measure.missing_answers"
      :data="measure"
      :compact-sub-menu="true"
      :audition="true"
      class="single-measure-list-item"
    />
    <measure-questions
      v-if="loaded"
      :audition="true"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MeasureListItem from '@/components/clinician/patients/measures/MeasureListItem'
import MeasureQuestions from '@/components/clinician/patients/measures/MeasureQuestions'

export default {
  name: 'AuditionMeasureReport',
  components: {
    MeasureListItem,
    MeasureQuestions
  },
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    ...mapGetters({
      details: 'measureLibraryDetail',
      auditionData: 'getAuditionData',
      measure: 'getAuditionMeasureReport'
    })
  },
  created () {
    this.loaded = false
    if (!this.auditionData || !Object.values(this.auditionData).length) {
      this.$router.push({ name: 'MeasuresClinician' })
      return
    }

    const params = {
      measureId: this.details.measure_id,
      measureData: this.auditionData
    }
    this.$store.dispatch('GET_AUDITION_MEASURE_REPORT', params).then(() => {
      this.loaded = true
    })
  },
  methods: {
  }
}
</script>
<style lang="scss">
.report-container {
  padding-top: 61px;
}
</style>
